import * as types from './mutation-types'
import { MutationTree } from 'vuex'
import {
  MyTreeesState, Treee, TreeeId, TreeeTitle
} from '@/stores/myTreees/state'

const myTreeesMutations: MutationTree<MyTreeesState> = {
  [types.UPDATE_ITEMS] (state, items: Treee[]) {
    state.items = items
  }
}

export default myTreeesMutations
