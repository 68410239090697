import * as firebase from 'firebase/app'
import 'firebase/firestore'
import Timestamp = firebase.firestore.Timestamp

export type TreeeId = string;
export type TreeeTitle = string;
export type Treee = {
  id: TreeeId;
  title: TreeeTitle;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}

export type MyTreeesState = {
  items: Treee[];
}

const myTreeesState: MyTreeesState = {
  items: []
}

export default myTreeesState
