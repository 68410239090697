import { ActionTree } from 'vuex'
import { RootState } from '../state'
import {
  NodeId,
  NodeOrder,
  TreeeNode,
  TreeeNodeV1,
  TreeeState
} from './state'
import * as types from './mutation-types'

import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import firebaseConfig from '../../firebaseConfig'
import DocumentReference = firebase.firestore.DocumentReference
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const db = firebase.firestore()
const storage = firebase.storage().ref()
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
let docRef: DocumentReference

type EmptyNodePayload = {
  order: NodeOrder,
  parentNodeId: NodeId,
  newNodeId: NodeId,
  newNextSiblingNodeAll: TreeeNode[]
}

const treeeEditorActions: ActionTree<TreeeState, RootState> = {
  // Load treee & save
  getTreee ({ commit, rootState, dispatch }) {
    docRef = db.collection('users')
      .doc(rootState.route.params.userId)
      .collection('treees')
      .doc(rootState.route.params.treeeId)

    return docRef.get().then(doc => {
      const docData = doc.data() || { title: '' }
      commit(types.UPDATE_TITLE, docData.title)

      if (docData.version === undefined) {
        // migrate v1 -> 2
        return storage
          .child(`users/${rootState.route.params.userId}/treees/${rootState.route.params.treeeId}.json`)
          .getDownloadURL()
          .then(url => fetch(url))
          .then(response => response.json())
          .then((treeeData: TreeeNodeV1[]) => {
            return docRef.update({
              version: 2
            }).then(() => {
              function flatNode (ar:TreeeNodeV1[], pid = 'root'): Promise<void> {
                return Promise.all(ar.map((i, index) => {
                  return docRef.collection('items').add({
                    label: i.label,
                    description: i.description,
                    order: index,
                    parentId: pid,
                    children: {
                      isVisible: i.children.isVisible
                    },
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp()
                  }).then(createdItem => {
                    if (i.children.items.length > 0) {
                      return flatNode(i.children.items, createdItem.id)
                    }
                  })
                })).then(() => {
                  // noop
                })
              }
              return flatNode(treeeData).then(() => {
                return docRef.get().then(doc => {
                  return doc.data()
                })
              })
            })
          })
      } else {
        return docData
      }
    })
      .then(() => docRef.collection('items').get())
      .then(querySnapshot => {
        const payload: TreeeNode[] = []
        querySnapshot.forEach(doc => {
          const data: TreeeNode = doc.data() as TreeeNode
          payload.push({
            id: doc.id,
            parentId: data.parentId,
            label: data.label,
            description: data.description,
            children: {
              isVisible: data.children.isVisible
            },
            order: data.order
          })
        })
        if (payload.length === 0) {
          dispatch('addChild')
        }
        commit(types.ADD_TREEE_NODE_LIST, payload)
      })
  },
  clearTreee ({ commit }) {
    commit(types.UPDATE_NODE_SELECTION, '')
    commit(types.UPDATE_TITLE, '')
    commit(types.ADD_TREEE_NODE_LIST, [])
  },

  // Treee Edit
  updateLabel ({ commit }, payload) {
    commit(types.UPDATE_NODE_LABEL, {
      id: payload.nodeId,
      label: payload.value
    })
    commit(types.UPDATE_EDIT_TARGET, 'none')
    return docRef.collection('items')
      .doc(payload.nodeId)
      .update({
        label: payload.value,
        updatedAt: serverTimestamp()
      })
  },
  updateDescription ({ commit }, payload) {
    commit(types.UPDATE_NODE_DESCRIPTION, {
      id: payload.nodeId,
      description: payload.value
    })
    commit(types.UPDATE_EDIT_TARGET, 'none')
    return docRef.collection('items')
      .doc(payload.nodeId)
      .update({
        description: payload.value,
        updatedAt: serverTimestamp()
      })
  },
  toggleChildrenVisibility ({ commit, state }, id: NodeId) {
    const newVisibility = !state.flatTreee[id].children.isVisible
    commit(types.UPDATE_CHILDREN_IS_VISIBLE, id)
    return docRef.collection('items')
      .doc(id)
      .update({
        children: {
          isVisible: newVisibility
        },
        updatedAt: serverTimestamp()
      })
  },
  editTypeLabel ({ commit }) {
    commit(types.UPDATE_EDIT_TARGET, 'label')
  },
  editTypeDescription ({ commit }) {
    commit(types.UPDATE_EDIT_TARGET, 'description')
  },
  clearEditType ({ commit }) {
    commit(types.UPDATE_EDIT_TARGET, 'none')
  },

  // Add & Remove
  addSiblings ({ state, dispatch }) {
    const nextSiblingNodeIdAll = _nextSiblingNodeIdAll(state.selectedNodeId, state.flatTreee)
    const emptyNodePayload: EmptyNodePayload = {
      order: state.flatTreee[state.selectedNodeId].order + 1,
      parentNodeId: state.flatTreee[state.selectedNodeId].parentId,
      newNodeId: docRef.collection('items').doc().id,
      newNextSiblingNodeAll: nextSiblingNodeIdAll.map(nodeId => state.flatTreee[nodeId])
    }

    dispatch('_addEmptyNode', emptyNodePayload)
  },
  addChild ({ state, dispatch }) {
    const childNodeIdAll = _childNodeIdAll(state.selectedNodeId, state.flatTreee)
    const emptyNodePayload: EmptyNodePayload = {
      order: 0,
      parentNodeId: state.selectedNodeId,
      newNodeId: docRef.collection('items').doc().id,
      newNextSiblingNodeAll: childNodeIdAll.map(nodeId => state.flatTreee[nodeId])
    }

    dispatch('_addEmptyNode', emptyNodePayload)
  },
  _addEmptyNode ({ commit }, payload: EmptyNodePayload) {
    const newNodeData = {
      label: '',
      description: '',
      order: payload.order,
      parentId: payload.parentNodeId,
      children: {
        isVisible: true
      }
    }
    const batch = db.batch()

    payload.newNextSiblingNodeAll.forEach(treeeNode => {
      batch.update(docRef.collection('items').doc(treeeNode.id), {
        order: treeeNode.order + 1,
        updatedAt: serverTimestamp()
      })
    })

    batch.set(docRef.collection('items').doc(payload.newNodeId), Object.assign({}, newNodeData, {
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    }))

    commit(types.ADD_TREEE_NODE, Object.assign({}, newNodeData, { id: payload.newNodeId }))
    commit(types.UPDATE_NODE_ORDER, payload.newNextSiblingNodeAll.map(treeeNode => {
      return { id: treeeNode.id, order: treeeNode.order + 1 }
    }))
    commit(types.UPDATE_NODE_SELECTION, payload.newNodeId)
    commit(types.UPDATE_EDIT_TARGET, 'label')

    return batch.commit()
  },
  deleteNode ({ commit, state, dispatch }) {
    if (state.selectedNodeId === 'root') return

    let newFocusNodeId = _previousSiblingNodeId(state.selectedNodeId, state.flatTreee)
    if (newFocusNodeId === null) {
      newFocusNodeId = _parentNodeId(state.selectedNodeId, state.flatTreee)
    }
    if (newFocusNodeId === null) {
      newFocusNodeId = 'root'
    }
    const payload = _nextSiblingNodeIdAll(state.selectedNodeId, state.flatTreee)
      .map(nodeId => {
        return { id: nodeId, order: state.flatTreee[nodeId].order - 1 }
      })

    const payload2: NodeId[] = [state.selectedNodeId].concat(_descendantNodeIdAll(state.selectedNodeId, state.flatTreee))

    const batch = db.batch()

    payload.forEach(item => {
      batch.update(docRef.collection('items').doc(item.id), {
        order: item.order,
        updatedAt: serverTimestamp()
      })
    })

    payload2.forEach(item => {
      batch.delete(docRef.collection('items').doc(item))
    })

    commit(types.DELETE_TREEE_NODE, payload2)
    commit(types.UPDATE_NODE_ORDER, payload)

    if (Object.keys(state.flatTreee).length === 0) {
      commit(types.UPDATE_NODE_SELECTION, 'root')
      dispatch('addChild')
    } else {
      commit(types.UPDATE_NODE_SELECTION, newFocusNodeId !== 'root' ? newFocusNodeId : _rootTopNodeId(state.flatTreee))
    }

    return batch.commit()
  },

  // Clipboard manipulation
  copyNode ({ commit, state }) {
    const nodeId = state.selectedNodeId
    const node = state.flatTreee[nodeId]
    const payload: TreeeNode[] = [{
      id: nodeId,
      label: node.label,
      description: node.description,
      parentId: 'unknown',
      order: node.order,
      children: {
        isVisible: node.children.isVisible
      }
    }].concat(getPartialTreee(nodeId, state.flatTreee))

    function getPartialTreee (id: string, flatTreee: {[key: string]: TreeeNode}): TreeeNode[] {
      const partialTree: TreeeNode[] = []
      _getPartialTreee(id, flatTreee)
      return partialTree
      function _getPartialTreee (id: string, flatTreee: {[key: string]: TreeeNode}) {
        Object.keys(flatTreee)
          .map(nodeId => flatTreee[nodeId])
          .filter(treeeNode => treeeNode.parentId === id)
          .map(treeeNode => {
            partialTree.push({
              id: treeeNode.id,
              label: treeeNode.label,
              description: treeeNode.description,
              order: treeeNode.order,
              parentId: treeeNode.parentId,
              children: {
                isVisible: treeeNode.children.isVisible
              }
            })
            return treeeNode
          })
          .forEach(treeeNode => _getPartialTreee(treeeNode.id, flatTreee))
      }
    }

    commit(types.UPDATE_CLIPBOARD, payload)
  },
  cutNode ({ dispatch }) {
    dispatch('copyNode')
    dispatch('deleteNode')
  },
  pasteNodeToChild ({ state, dispatch }) {
    if (state.clipBoard === null) return
    const nodeList = _alternateId('unknown', state.selectedNodeId, state.clipBoard, 0)
    const childNodeIdAll = _childNodeIdAll(state.selectedNodeId, state.flatTreee)

    dispatch('_pasteNode', { nodeList: nodeList, newNextSiblingNodeAll: childNodeIdAll.map(nodeId => state.flatTreee[nodeId]) })
  },
  pasteNodeToSibling ({ state, dispatch }) {
    if (state.clipBoard === null) return
    const nodeList = _alternateId('unknown', state.flatTreee[state.selectedNodeId].parentId, state.clipBoard, state.flatTreee[state.selectedNodeId].order + 1)
    const nextSiblingNodeIdAll = _nextSiblingNodeIdAll(state.selectedNodeId, state.flatTreee)

    dispatch('_pasteNode', { nodeList: nodeList, newNextSiblingNodeAll: nextSiblingNodeIdAll.map(nodeId => state.flatTreee[nodeId]) })
  },
  _pasteNode ({ commit }, payload: {nodeList: TreeeNode[], newNextSiblingNodeAll: TreeeNode[]}) {
    const batch = db.batch()

    payload.newNextSiblingNodeAll.forEach(treeeNode => {
      batch.update(docRef.collection('items').doc(treeeNode.id), {
        order: treeeNode.order + 1,
        updatedAt: serverTimestamp()
      })
    })

    payload.nodeList.forEach(treeeNode => {
      const nodeData = {
        label: treeeNode.label,
        description: treeeNode.description,
        order: treeeNode.order,
        parentId: treeeNode.parentId,
        children: {
          isVisible: treeeNode.children.isVisible
        }
      }
      batch.set(docRef.collection('items').doc(treeeNode.id), Object.assign(
        {},
        nodeData,
        {
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        }))
    })

    commit(types.UPDATE_NODE_ORDER, payload.newNextSiblingNodeAll.map(treeeNode => {
      return { id: treeeNode.id, order: treeeNode.order + 1 }
    }))
    commit(types.ADD_TREEE_NODE_LIST, payload.nodeList)

    return batch.commit()
  },

  // Select Treee node.
  selectNode ({ commit, state }, nodeId) {
    if (state.selectedNodeId !== nodeId) {
      commit(types.UPDATE_NODE_SELECTION, nodeId)
    }
  },
  unselectNode ({ commit }) {
    commit(types.UPDATE_NODE_SELECTION, '')
  },
  selectUpperNode ({ commit, state }) {
    const previousSiblingNodeId = _previousSiblingNodeId(state.selectedNodeId, state.flatTreee)
    if (previousSiblingNodeId === null) return
    commit(types.UPDATE_NODE_SELECTION, previousSiblingNodeId)
  },
  selectLowerNode ({ commit, state }) {
    const nextSiblingNodeId = _nextSiblingNodeId(state.selectedNodeId, state.flatTreee)
    if (nextSiblingNodeId === null) return
    commit(types.UPDATE_NODE_SELECTION, nextSiblingNodeId)
  },
  selectRightNode ({ commit, state }) {
    const firstChildNodeId = _firstChildNodeId(state.selectedNodeId, state.flatTreee)
    if (firstChildNodeId === null) return
    commit(types.UPDATE_NODE_SELECTION, firstChildNodeId)
  },
  selectLeftNode ({ commit, state }) {
    const parentNodeId = _parentNodeId(state.selectedNodeId, state.flatTreee)
    if (parentNodeId === null) return
    commit(types.UPDATE_NODE_SELECTION, parentNodeId)
  },
  selectRootNode ({ commit, state }) {
    commit(types.UPDATE_NODE_SELECTION, _rootTopNodeId(state.flatTreee))
  },

  // Move node
  moveUpNode ({ commit, state }) {
    const selectedNode = state.flatTreee[state.selectedNodeId]
    const previousSiblingNodeId = _previousSiblingNodeId(state.selectedNodeId, state.flatTreee)
    if (previousSiblingNodeId === null) return

    const batch = db.batch()

    batch.update(docRef.collection('items').doc(selectedNode.id), {
      order: selectedNode.order - 1,
      updatedAt: serverTimestamp()
    })

    batch.update(docRef.collection('items').doc(previousSiblingNodeId), {
      order: state.flatTreee[previousSiblingNodeId].order + 1,
      updatedAt: serverTimestamp()
    })

    commit(types.UPDATE_NODE_ORDER, [
      { id: selectedNode.id, order: selectedNode.order - 1 },
      { id: previousSiblingNodeId, order: state.flatTreee[previousSiblingNodeId].order + 1 }
    ])

    return batch.commit()
  },
  moveDownNode ({ commit, state }) {
    const selectedNode = state.flatTreee[state.selectedNodeId]
    const nextSiblingNodeId = _nextSiblingNodeId(state.selectedNodeId, state.flatTreee)
    if (nextSiblingNodeId === null) return
    const nextSiblingNode = state.flatTreee[nextSiblingNodeId]

    const batch = db.batch()

    batch.update(docRef.collection('items').doc(selectedNode.id), {
      order: selectedNode.order + 1,
      updatedAt: serverTimestamp()
    })

    batch.update(docRef.collection('items').doc(nextSiblingNode.id), {
      order: nextSiblingNode.order - 1,
      updatedAt: serverTimestamp()
    })

    commit(types.UPDATE_NODE_ORDER, [
      { id: selectedNode.id, order: selectedNode.order + 1 },
      { id: nextSiblingNode.id, order: nextSiblingNode.order - 1 }
    ])

    return batch.commit()
  },
  moveRightNode ({ commit, state }) {
    const order = state.flatTreee[state.selectedNodeId].order
    if (order === 0) return

    const newParentNodeId = _previousSiblingNodeId(state.selectedNodeId, state.flatTreee)
    if (newParentNodeId === null) return

    const nextSiblingNodeIdAll = _nextSiblingNodeIdAll(state.selectedNodeId, state.flatTreee)
    const newNextSiblingNodeIdAll = _childNodeIdAll(newParentNodeId, state.flatTreee)

    const payload:{id: string, order: number}[] = []

    nextSiblingNodeIdAll.forEach(nodeId => payload.push({ id: nodeId, order: state.flatTreee[nodeId].order - 1 }))
    newNextSiblingNodeIdAll.forEach(nodeId => payload.push({ id: nodeId, order: state.flatTreee[nodeId].order + 1 }))

    const batch = db.batch()

    batch.update(docRef.collection('items').doc(state.selectedNodeId), {
      order: 0,
      parentId: newParentNodeId,
      updatedAt: serverTimestamp()
    })

    payload.forEach(item => {
      batch.update(docRef.collection('items').doc(item.id), {
        order: item.order,
        updatedAt: serverTimestamp()
      })
    })

    commit(types.UPDATE_NODE_PARENT, { id: state.selectedNodeId, order: 0, parentId: newParentNodeId })
    commit(types.UPDATE_NODE_ORDER, payload)

    return batch.commit()
  },
  moveLeftNode ({ commit, state }) {
    const parentNodeId = _parentNodeId(state.selectedNodeId, state.flatTreee)
    if (parentNodeId === null) return

    const nextSiblingNodeIdAll = _nextSiblingNodeIdAll(state.selectedNodeId, state.flatTreee)
    const newNextSiblingNodeIdAll = _nextSiblingNodeIdAll(parentNodeId, state.flatTreee)

    const payload:{id: string, order: number}[] = []

    nextSiblingNodeIdAll.forEach(nodeId => payload.push({ id: nodeId, order: state.flatTreee[nodeId].order - 1 }))
    newNextSiblingNodeIdAll.forEach(nodeId => payload.push({ id: nodeId, order: state.flatTreee[nodeId].order + 1 }))

    const batch = db.batch()

    batch.update(docRef.collection('items').doc(state.selectedNodeId), {
      order: state.flatTreee[parentNodeId].order + 1,
      parentId: state.flatTreee[parentNodeId].parentId,
      updatedAt: serverTimestamp()
    })

    payload.forEach(item => {
      batch.update(docRef.collection('items').doc(item.id), {
        order: item.order,
        updatedAt: serverTimestamp()
      })
    })

    commit(types.UPDATE_NODE_PARENT, { id: state.selectedNodeId, order: state.flatTreee[parentNodeId].order + 1, parentId: state.flatTreee[parentNodeId].parentId })
    commit(types.UPDATE_NODE_ORDER, payload)

    return batch.commit()
  },

  // Shortkey
  executeShortkeySelection ({ state, dispatch, getters }, srcKey) {
    if (state.selectedNodeId === 'root') {
      return dispatch('selectRootNode')
    }
    switch (srcKey) {
      case 'up':
        dispatch('selectUpperNode')
        break
      case 'down':
        dispatch('selectLowerNode')
        break
      case 'right':
        dispatch('selectRightNode')
        break
      case 'left':
        dispatch('selectLeftNode')
        break
    }
  },
  executeShortkeyEditorAction ({ state, dispatch }, srcKey) {
    if (state.selectedNodeId === '') { return }
    switch (srcKey) {
      case 'addChild':
        dispatch('addChild')
        break
      case 'addSiblings':
        dispatch('addSiblings')
        break
      case 'editDescription':
        dispatch('editTypeDescription')
        break
      case 'editLabel':
        dispatch('editTypeLabel')
        break
      case 'deleteNode':
        dispatch('deleteNode')
        break
      case 'copyNode':
        dispatch('copyNode')
        break
      case 'cutNode':
        dispatch('cutNode')
        break
      case 'pasteNodeToChild':
        dispatch('pasteNodeToChild')
        break
      case 'pasteNodeToSibling':
        dispatch('pasteNodeToSibling')
        break
      case 'moveUpNode':
        dispatch('moveUpNode')
        break
      case 'moveDownNode':
        dispatch('moveDownNode')
        break
      case 'moveRightNode':
        dispatch('moveRightNode')
        break
      case 'moveLeftNode':
        dispatch('moveLeftNode')
        break
    }
  }
}

function _nextSiblingNodeId (nodeId: NodeId, flatTreee: {[key:string]: TreeeNode}): NodeId | null {
  const parentId = flatTreee[nodeId].parentId
  const order = flatTreee[nodeId].order

  const result = Object.keys(flatTreee)
    .map(nodeId => flatTreee[nodeId])
    .filter(treeeNode => treeeNode.parentId === parentId)
    .find(treeeNode => treeeNode.order === order + 1)

  if (result === undefined) return null

  return result.id
}

function _nextSiblingNodeIdAll (nodeId: NodeId, flatTreee: {[key:string]: TreeeNode}): NodeId[] {
  const parentId = flatTreee[nodeId].parentId
  const order = flatTreee[nodeId].order

  return Object.keys(flatTreee)
    .map(nodeId => flatTreee[nodeId])
    .filter(treeeNode => treeeNode.parentId === parentId)
    .filter(treeeNode => treeeNode.order > order)
    .sort((a, b) => a.order - b.order)
    .map(treeeNode => treeeNode.id)
}

function _previousSiblingNodeId (nodeId: NodeId, flatTreee: {[key:string]: TreeeNode}): NodeId | null {
  if (flatTreee[nodeId].order === 0) return null

  const parentId = flatTreee[nodeId].parentId
  const order = flatTreee[nodeId].order

  const result = Object.keys(flatTreee)
    .map(nodeId => flatTreee[nodeId])
    .filter(treeeNode => treeeNode.parentId === parentId)
    .find(treeeNode => treeeNode.order === order - 1)

  if (result === undefined) return null

  return result.id
}

function _childNodeIdAll (nodeId: NodeId, flatTreee: {[key:string]: TreeeNode}): NodeId[] {
  return Object.keys(flatTreee)
    .map(nodeId => flatTreee[nodeId])
    .filter(treeeNode => treeeNode.parentId === nodeId)
    .sort((a, b) => a.order - b.order)
    .map(treeeNode => treeeNode.id)
}

function _firstChildNodeId (nodeId: NodeId, flatTreee:{[key:string]: TreeeNode}): NodeId | null {
  const result = Object.keys(flatTreee)
    .map(nodeId => flatTreee[nodeId])
    .filter(treeeNode => treeeNode.parentId === nodeId)
    .find(treeeNode => treeeNode.order === 0)

  if (result === undefined) return null

  return result.id
}

function _parentNodeId (nodeId: NodeId, flatTreee: {[key:string]: TreeeNode}): NodeId | null {
  return flatTreee[nodeId].parentId === 'root' ? null : flatTreee[nodeId].parentId
}

function _descendantNodeIdAll (nodeId: NodeId, flatTreee: {[key: string]: TreeeNode}): NodeId[] {
  const results: NodeId[] = []
  f(nodeId, flatTreee)
  function f (nodeId: string, flatTreee: {[key: string]: TreeeNode}) {
    Object.keys(flatTreee)
      .map(nodeId => flatTreee[nodeId])
      .filter(treeeNode => treeeNode.parentId === nodeId)
      .forEach(treerNode => {
        results.push(treerNode.id)
        f(treerNode.id, flatTreee)
      })
  }
  return results
}

function _rootTopNodeId (flatTreee:{[key:string]: TreeeNode}): string {
  return Object.keys(flatTreee)
    .map(nodeId => flatTreee[nodeId])
    .filter(treeeNode => treeeNode.parentId === 'root')
    .reduce((a, b) => a.order < b.order ? a : b)
    .id
}

function _alternateId (parentNodeId: string, newParentNodeId: string, items: TreeeNode[], newOrder: number): TreeeNode[] {
  const alteredNodeList: TreeeNode[] = []

  f(parentNodeId, newParentNodeId, items, newOrder)

  function f (parentNodeId: string, newParentNodeId: string, items: TreeeNode[], newOrder?: number) {
    items.filter(treeeNode => treeeNode.parentId === parentNodeId)
      .forEach(treeeNode => {
        const newNodeId = docRef.collection('items').doc().id
        alteredNodeList.push({
          id: newNodeId,
          label: treeeNode.label,
          description: treeeNode.description,
          order: newOrder !== undefined ? newOrder : treeeNode.order,
          parentId: newParentNodeId,
          children: {
            isVisible: treeeNode.children.isVisible
          }
        })
        f(treeeNode.id, newNodeId, items)
      })
  }

  return alteredNodeList
}

export default treeeEditorActions

export const forTest = {
  _nextSiblingNodeId,
  _nextSiblingNodeIdAll,
  _previousSiblingNodeId,
  _childNodeIdAll,
  _firstChildNodeId,
  _parentNodeId,
  _descendantNodeIdAll,
  _rootTopNodeId,
  _alternateId
}
