import { ActionTree } from 'vuex'
import { RootState } from '../state'
import { AuthState } from './state'

import * as types from './mutation-types'

import * as firebase from 'firebase/app'
import 'firebase/auth'

const provider = new firebase.auth.GoogleAuthProvider()

const authActions: ActionTree<AuthState, RootState> = {
  initialize ({ commit }) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        commit(types.UPDATE_USER_ID, user.uid)
        commit(types.UPDATE_DISPLAY_NAME, user.displayName)
        commit(types.UPDATE_AUTHORIZED, true)
      } else {
        commit(types.UPDATE_USER_ID, '')
        commit(types.UPDATE_DISPLAY_NAME, '')
        commit(types.UPDATE_AUTHORIZED, false)
      }
      commit(types.UPDATE_INITIALIZED, true)
    })
  },
  signInWithGoogleAccount () {
    firebase.auth().signInWithRedirect(provider)
  },
  signOut () {
    firebase.auth().signOut().then(() => {
      location.href = '/'
    })
  }
}

export default authActions
