import Vue from 'vue'
import { TreeeState, TreeeNode } from './state'

const treeeGetters = {
  treeeNodeSiblings (state: TreeeState): (nodeId: string) => TreeeNode[] {
    return (nodeId: string) => {
      return Object.keys(state.flatTreee)
        .map(nodeId => state.flatTreee[nodeId])
        .filter(treeeNode => treeeNode.parentId === nodeId)
        .sort((a, b) => a.order - b.order)
    }
  },
  structuredTreee (state: TreeeState): (ops: {index: boolean, description: boolean}) => string {
    return (ops: {index: boolean, description: boolean}) => {
      const lines: string[] = []
      function createLine (nodeId: string, depth: number, parentBranch?: string) {
        Object.keys(state.flatTreee)
          .map(nodeId => state.flatTreee[nodeId])
          .filter(treeeNode => treeeNode.parentId === nodeId)
          .sort((a, b) => a.order - b.order)
          .forEach((treeeNode, index) => {
            const indent = ' '.repeat(depth * 4)
            const branch = parentBranch ? `${parentBranch}.${index + 1}` : `${index + 1}`
            const label = treeeNode.label.replace(/\n/g, ' ')
            const description = treeeNode.description !== '' ? `(${treeeNode.description.replace(/\n/g, ' ')})` : ''
            lines.push(`${indent}${ops.index ? branch : ''} ${label}${ops.description ? ` ${description}` : ''}`)
            createLine(treeeNode.id, depth + 1, branch)
          })
      }
      createLine('root', 0)
      return lines.join('\n')
    }
  },
  structuredTreee2 (state: TreeeState) {
    const lines: string[] = []
    function createLine (nodeId: string, depth: number, parentBranch?: string) {
      Object.keys(state.flatTreee)
        .map(nodeId => state.flatTreee[nodeId])
        .filter(treeeNode => treeeNode.parentId === nodeId)
        .sort((a, b) => a.order - b.order)
        .forEach((treeeNode, index) => {
          const indent = ' '.repeat(depth * 4)
          const branch = parentBranch ? `${parentBranch}.${index + 1}` : `${index + 1}`
          const label = treeeNode.label.replace(/\n/g, ' ')
          const description = treeeNode.description !== '' ? `(${treeeNode.description.replace(/\n/g, ' ')})` : ''
          lines.push(`${indent}${branch} ${label} ${description}`)
          createLine(treeeNode.id, depth + 1, branch)
        })
    }
    createLine('root', 0)
    return lines.join('\n')
  }
}
export default treeeGetters
