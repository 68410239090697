import Vue from 'vue'
import {
  EditTargetType,
  TreeeState,
  TreeeNode, SaveStateMessage
} from './state'
import * as types from './mutation-types'
import { MutationTree } from 'vuex'
import { TreeeTitle } from '@/stores/myTreees/state'

const treeeEditorMutations: MutationTree<TreeeState> = {
  [types.ADD_TREEE_NODE] (state, newTreeeNode: TreeeNode) {
    Vue.set(state.flatTreee, newTreeeNode.id, newTreeeNode)
  },
  [types.ADD_TREEE_NODE_LIST] (state, payload: TreeeNode[]) {
    payload.forEach(item => {
      Vue.set(state.flatTreee, item.id, item)
    })
  },
  [types.DELETE_TREEE_NODE] (state, payload: string[]) {
    payload.forEach(item => {
      Vue.delete(state.flatTreee, item)
    })
  },
  [types.UPDATE_NODE_LABEL] (state, payload: {id: string, label: string}) {
    state.flatTreee[payload.id].label = payload.label
  },
  [types.UPDATE_CHILDREN_IS_VISIBLE] (state, id) {
    state.flatTreee[id].children.isVisible = !state.flatTreee[id].children.isVisible
  },
  [types.UPDATE_NODE_DESCRIPTION] (state, payload: {id: string, description: string}) {
    state.flatTreee[payload.id].description = payload.description
  },
  [types.UPDATE_NODE_ORDER] (state, payload: [{id: string, order: number}]) {
    payload.forEach(target => {
      state.flatTreee[target.id].order = target.order
    })
  },
  [types.UPDATE_NODE_PARENT] (state, payload: {id: string, order: number, parentId: string}) {
    state.flatTreee[payload.id].parentId = payload.parentId
    state.flatTreee[payload.id].order = payload.order
  },

  [types.UPDATE_TITLE] (state, title: TreeeTitle) {
    state.title = title
  },
  [types.UPDATE_CLIPBOARD] (state, payload: TreeeNode[]) {
    state.clipBoard = payload
  },
  [types.UPDATE_NODE_SELECTION] (state, nodeId: string) {
    state.selectedNodeId = nodeId
  },
  [types.UPDATE_EDIT_TARGET] (state, editTargetType: EditTargetType) {
    state.editTargetType = editTargetType
  },
  [types.UPDATE_SAVE_STATE_MESSAGE] (state, saveStateMessage: SaveStateMessage) {
    state.saveStateMessage = saveStateMessage
  }
}

export default treeeEditorMutations

export const forTest = {
  // _findTargetNode,
  // _findNodeListIncludingTargetNode
}
