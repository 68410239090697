import { ActionTree } from 'vuex'
import { RootState } from '../state'
import {
  MyTreeesState, Treee
} from './state'

import * as types from './mutation-types'

import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const db = firebase.firestore()
const storage = firebase.storage().ref()

let myTreeesUnsubscribe: any
const myTreeesActions: ActionTree<MyTreeesState, RootState> = {
  subscribeMyTreees ({ commit, rootState }) {
    myTreeesUnsubscribe = db.collection('users').doc(rootState.route.params.userId)
      .collection('treees').orderBy('createdAt', 'desc').onSnapshot(treees => {
        const items: Treee[] = []
        treees.forEach(treee => {
          items.push({
            id: treee.id,
            title: treee.data().title,
            updatedAt: treee.data().updatedAt,
            createdAt: treee.data().createdAt
          })
        })
        commit(types.UPDATE_ITEMS, items)
      })
  },
  unsubscribeMyTreees ({ commit }) {
    myTreeesUnsubscribe()
    commit(types.UPDATE_ITEMS, [])
  },
  addNewTreee ({ rootState }, newTreeeTitle) {
    return db.collection('users')
      .doc(rootState.route.params.userId)
      .collection('treees')
      .add({
        title: newTreeeTitle,
        version: 2,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(createdTreee => {
        return createdTreee.id
      })
  },
  updateTreee ({ rootState, dispatch }, { treeeId, titleValue }) {
    return db.collection('users')
      .doc(rootState.route.params.userId)
      .collection('treees')
      .doc(treeeId)
      .update({
        title: titleValue,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
  },
  deleteTreee ({ rootState, dispatch }, treeeId) {
    return db
      .collection('users')
      .doc(rootState.route.params.userId)
      .collection('treees')
      .doc(treeeId)
      .delete()
      .then(() => {
        return storage.child(`users/${rootState.route.params.userId}/treees/${treeeId}.json`).delete()
      })
      .catch(err => {
        if (err.code === 'storage/object-not-found') { // it's ok
          return Promise.resolve()
        }
      })
  }
}

export default myTreeesActions
