import {
  AuthorizedStatus,
  AuthState, DisplayName, InitializedStatus, UserId
} from './state'
import * as types from './mutation-types'
import { MutationTree } from 'vuex'

const authMutations: MutationTree<AuthState> = {
  [types.UPDATE_AUTHORIZED] (state, authorized: AuthorizedStatus) {
    state.authorized = authorized
  },
  [types.UPDATE_INITIALIZED] (state, initialized: InitializedStatus) {
    state.initialized = initialized
  },
  [types.UPDATE_DISPLAY_NAME] (state, displayName: DisplayName) {
    state.displayName = displayName
  },
  [types.UPDATE_USER_ID] (state, userId: UserId) {
    state.userId = userId
  }
}

export default authMutations
