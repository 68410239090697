<template>
  <div id="app">
    <template v-if="initialized">
      <router-view />
    </template>
    <div class="splash" v-else>
      <TreeeMark class="mark"/>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TreeeMark from './images/TreeeMark.svg'

const authStore = createNamespacedHelpers('authStore')
export default {
  components: {
    TreeeMark
  },
  beforeMount () {
    this.initialize()
  },
  computed: {
    ...authStore.mapState([
      'authorized',
      'initialized'
    ])
  },
  watch: {
    authorized () {

    }
  },
  methods: {
    ...authStore.mapActions([
      'initialize'
    ])
  }
}
</script>

<style lang="scss">
html {
  font-size: 10px;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#app {
  @import url('https://fonts.googleapis.com/css?family=M+PLUS+1p:300,400,500,700,800&subset=cyrillic,cyrillic-ext,greek,greek-ext,hebrew,japanese,latin-ext,vietnamese');

  font-family: 'M PLUS 1p', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>

<style scoped lang="scss">
  .splash {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mark {
    width: 50px;
  }
</style>
