import { TreeeTitle } from '@/stores/myTreees/state'

export type NodeId = string;
export type NodeLabel = string;
export type NodeDescription = string;
export type ChildNodeListV1 = TreeeNodeV1[];
export type EditTargetType = 'label' | 'description' | 'none';
export type SaveStateMessage = string;
export type NodeOrder = number;

export type Children = {
  isVisible: boolean;
}
export type ChildrenV1 = {
  isVisible: boolean;
  items: ChildNodeListV1;
}
export type TreeeNode = {
  id: NodeId;
  parentId: NodeId;
  label: NodeLabel;
  description: NodeDescription;
  children: Children;
  order: NodeOrder;
}
export type TreeeNodeV1 = {
  id: NodeId;
  parentId: NodeId;
  label: NodeLabel;
  description: NodeDescription;
  children: ChildrenV1;
  order: NodeOrder;
}

export type TreeeState = {
  title: TreeeTitle;
  flatTreee: {[key: string]: TreeeNode};
  clipBoard: TreeeNode[] | null;
  selectedNodeId: NodeId;
  saveStateMessage: SaveStateMessage;
  editTargetType: EditTargetType;
}

const treeeEditorState: TreeeState = {
  title: '',
  selectedNodeId: 'root',
  editTargetType: 'none',
  saveStateMessage: '',
  clipBoard: null,
  flatTreee: {}
}

export default treeeEditorState
