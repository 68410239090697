export type AuthorizedStatus = boolean;
export type InitializedStatus = boolean;
export type UserId = string;
export type DisplayName = string;

export type AuthState = {
  authorized: AuthorizedStatus;
  initialized: InitializedStatus;
  userId: UserId;
  displayName: DisplayName;
}

const authState: AuthState = {
  authorized: false,
  initialized: false,
  userId: '',
  displayName: ''
}

export default authState
