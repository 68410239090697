import './firebaseInit'
import Vue from 'vue'
import App from './App.vue'
import { sync } from 'vuex-router-sync'
import router from './router'
import store from './store'
import 'vue-material-design-icons/styles.css'

// @ts-ignore
import TextareaAutosize from 'vue-textarea-autosize'

sync(store, router)

// tslint:disable-next-line:no-var-requires
Vue.use(TextareaAutosize)

// tslint:disable-next-line:no-var-requires
Vue.use(require('vue-shortkey'))

Vue.config.productionTip = false

Vue.directive('focus', {
  inserted (el) {
    el.focus()
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
