export default {
  apiKey: 'AIzaSyBtbtkMYuZ1EL-UCuA8LnyvA77ThPvru6s',
  authDomain: 'treee-fd902.firebaseapp.com',
  databaseURL: 'https://treee-fd902.firebaseio.com',
  projectId: 'treee-fd902',
  storageBucket: 'treee-fd902.appspot.com',
  messagingSenderId: '829649217416',
  appId: '1:829649217416:web:81e637c6b94ca4fe',
  measurementId: 'G-L0LQ1Z6GWK'
}
