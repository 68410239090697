export const UPDATE_CHILDREN_IS_VISIBLE = 'UPDATE_CHILDREN_IS_VISIBLE'
export const UPDATE_NODE_SELECTION = 'UPDATE_NODE_SELECTION'
export const UPDATE_EDIT_TARGET = 'UPDATE_EDIT_TARGET'
export const UPDATE_TITLE = 'UPDATE_TITLE'
export const UPDATE_IN_SAVE = 'UPDATE_IN_SAVE'
export const UPDATE_SAVE_STATE_MESSAGE = 'UPDATE_SAVE_STATE_MESSAGE'
export const UPDATE_CLIPBOARD = 'UPDATE_CLIPBOARD'
export const UPDATE_NODE_LABEL = 'UPDATE_NODE_LABEL'
export const UPDATE_NODE_DESCRIPTION = 'UPDATE_NODE_DESCRIPTION'
export const UPDATE_NODE_ORDER = 'UPDATE_NODE_ORDER'
export const UPDATE_NODE_PARENT = 'UPDATE_NODE_PARENT'
export const ADD_TREEE_NODE = 'ADD_TREEE_NODE'
export const DELETE_TREEE_NODE = 'DELETE_TREEE_NODE'
export const ADD_TREEE_NODE_LIST = 'ADD_TREEE_NODE_LIST'
