import { Module } from 'vuex'
import { RootState } from '../state'
import actions from './actions'
import mutations from './mutations'
import state, { AuthState } from './state'

const authStore: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default authStore
