import Vue from 'vue'
import Vuex from 'vuex'
import state from './stores/state'
import treeeEditorStore from './stores/treeeEditor'
import authStore from './stores/auth'
import myTreeesStore from './stores/myTreees'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  modules: {
    treeeEditorStore,
    authStore,
    myTreeesStore
  }
})
