import { MyTreeesState } from './state'

const myTreeesGetters = {
  findTreeeById (state: MyTreeesState) {
    return (treeeId: string) => {
      return state.items.find(item => item.id === treeeId)
    }
  }
}

export default myTreeesGetters
